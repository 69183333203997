import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const loggedin = useSelector((state) => state.adminState.loggedin);

  if (!loggedin) {
    return <Navigate to="/sign-in" />;
  }
  return children;
};
export default ProtectedRoute;
