import { settingsActions } from "../actionTypes";

export const fetchSettingsInitiate = (data) => {
  return {
    type: settingsActions.FETCH_SETTINGS_INITIATE,
    payload: data,
  };
};

export const fetchSettingsSuccess = (data) => {
  return {
    type: settingsActions.FETCH_SETTINGS_SUCCESS,
    payload: data,
  };
};

export const fetchSettingsFail = (data) => {
  return {
    type: settingsActions.FETCH_SETTINGS_FAIL,
    payload: data,
  };
};

export const editSettingsInitiate = (data) => {
  return {
    type: settingsActions.EDIT_SETTINGS_INITIATE,
    payload: data,
  };
};

export const editSettingsSuccess = () => {
  return {
    type: settingsActions.EDIT_SETTINGS_SUCCESS,
  };
};

export const editSettingsFail = () => {
  return {
    type: settingsActions.EDIT_SETTINGS_FAIL,
  };
};

export const setUserSettingsInitiate = (data) => {
  return {
    type: settingsActions.SET_USER_SETTINGS_INITIATE,
    payload: data,
  };
};

export const setUserSettingsSuccess = (data) => {
  return {
    type: settingsActions.SET_USER_SETTINGS_SUCCESS,
    payload: data,
  };
};

export const setUserSettingsFail = () => {
  return {
    type: settingsActions.SET_USER_SETTINGS_FAIL,
  };
};
