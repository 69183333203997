import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

const Notfound = () => {
  const navigate = useNavigate();
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div>
        <h1 style={{ textAlign: "center", fontSize: "50px" }}>404</h1>
        <p style={{ textAlign: "center", fontSize: "35px" }}>
          The path you are looking for does not exists please go back or go home
        </p>
      </div>
      <Button color="primary" onClick={() => navigate("/home")}>
        Return Home
      </Button>
    </div>
  );
};

export default Notfound;
