import { put } from "redux-saga/effects";
import {
  addShopCategorySuccess,
  deleteShopCategorySuccess,
  editShopCategorySuccess,
  fetchShopCategorySuccess,
} from "../actionCreators/shopcategoryActions";
import { makereq, toaster } from "../../utils/utils";
var qs = require("qs");

export function* addShopcategorySaga({ payload }) {
  try {
    var data = qs.stringify({
      name: payload.name,
      description: payload.description,
    });
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const res = yield makereq("shopCategory", "post", data, headers);
    const shopcategoryData = res.data;
    yield put(addShopCategorySuccess({ shopcategoryData }));
    toaster("success", "Shop category added successfully");
  } catch (error) {
    toaster("error", "Error Occured");
  }
}

export function* fetchshopcategorySaga({ payload }) {
  try {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const res = yield makereq("shopCategory", "get", null, headers);
    const { data } = res;
    yield put(fetchShopCategorySuccess({ data }));
  } catch (error) {}
}

export function* deleteshopcategorySaga({ payload }) {
  try {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    yield makereq(`shopCategory/${payload}`, "delete", null, headers);
    yield put(deleteShopCategorySuccess({ payload }));
    toaster("success", "Shop category deleted successufully");
  } catch (error) {
    toaster("error", "Error Occured");
  }
}

export function* editshopcategorySaga({ payload }) {
  try {
    var data = qs.stringify(
      {
        name: payload.name,
        description: payload.description,
      },
      { encode: false }
    );
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const res = yield makereq(
      `shopCategory/${payload.id}`,
      "put",
      data,
      headers
    );
    const updateres = res.data;
    yield put(editShopCategorySuccess({ updateres }));
    toaster("success", res.message);
  } catch (error) {
    toaster("error", "Error Occured");
  }
}
