import { shopcategoryActions } from "../actionTypes";

const intitalState = {
  shopcategorylist: [],
};

export const shopcategoryReducer = (state = intitalState, action) => {
  const { payload, type } = action;
  switch (type) {
    case shopcategoryActions.FETCH_SHOPCATEGORY_INITIATE: {
      return {
        ...state,
      };
    }
    case shopcategoryActions.FETCH_SHOPCATEGORY_SUCCESS:
      return {
        ...state,
        shopcategorylist: payload.data,
      };
    case shopcategoryActions.DELETE_SHOPCATEGORY_INITIATE: {
      return {
        ...state,
      };
    }
    case shopcategoryActions.DELETE_SHOPCATEGORY_SUCCESS: {
      const res = state.shopcategorylist.filter(
        (itm) => itm._id !== payload.payload
      );
      return {
        ...state,
        shopcategorylist: res,
      };
    }
    case shopcategoryActions.ADD_SHOPCATEGORY_INITIATTE: {
      return {
        ...state,
      };
    }
    case shopcategoryActions.ADD_SHOPCATEGORY_SUCCESS: {
      const newObj = {
        _id: payload.shopcategoryData.id,
        name: payload.shopcategoryData.name,
        description: payload.shopcategoryData.description,
        createdAt: payload.shopcategoryData.createdAt,
      };
      const newshopcategorylist = [...state.shopcategorylist, newObj];
      return {
        ...state,
        shopcategorylist: newshopcategorylist,
      };
    }
    case shopcategoryActions.EDIT_SHOPCATEGORY_INITIATE: {
      return {
        ...state,
      };
    }
    case shopcategoryActions.EDIT_SHOPCATEGORY_SUCCESS: {
      const newObj = {
        _id: payload.updateres.id,
        name: payload.updateres.name,
        description: payload.updateres.description,
      };
      let index = state.shopcategorylist.findIndex((element) => {
        if (element._id === newObj._id) {
          return true;
        }
        return false;
      });
      const newArray = [...state.shopcategorylist];
      newArray.splice(index, 1, newObj);
      return {
        ...state,
        shopcategorylist: newArray,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
