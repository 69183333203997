import { orderActions } from "../actionTypes";

const intitalState = {
  orders: [],
};

export const orderReducer = (state = intitalState, action) => {
  const { payload, type } = action;
  switch (type) {
    case orderActions.FETCH_ORDER_INITIATE: {
      return {
        ...state,
      };
    }
    case orderActions.FETCH_ORDER_SUCCESS: {
      return {
        ...state,
        orders: payload.data,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
