import { watcher } from "./watcher";
import createSagaMiddleware from "redux-saga";
import { createStore, applyMiddleware } from "redux";
// import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
import rootReducer from "./rootReducer";

// const persistConfig = {
//   key: "root",
//   storage,
// };

const sagaMiddleWare = createSagaMiddleware();
// const persistedReducer = persistReducer(persistConfig, rootReducer);
// let store = createStore(persistedReducer, applyMiddleware(sagaMiddleWare));
let store = createStore(rootReducer, applyMiddleware(sagaMiddleWare));
// let persistor = persistStore(store);
sagaMiddleWare.run(watcher);

// export { store, persistor };
export { store };

// persist redux store logic is commented
