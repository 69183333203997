import { put } from "redux-saga/effects";
import {
  addproductcategorysuccess,
  deleteproductcategorysuccess,
  editproductcategorysuccess,
  fetchProductCategorySuccess,
} from "../actionCreators/productCategoryActions";
import { makereq, toaster } from "../../utils/utils";

var FormData = require("form-data");

export function* addproductcategorySaga({ payload }) {
  try {
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    const formData = new FormData();
    formData.append("catName", payload.name);
    formData.append("description", payload.description);
    formData.append("proCatImage", payload.image, payload.image.name);
    const res = yield makereq(`category`, "post", formData, headers);
    const { data } = res;
    yield put(addproductcategorysuccess({ data }));
    toaster("success", "Product Category Added Successfully");
  } catch (error) {
    toaster("error", "Error Occured");
  }
}

export function* editproductcategorySaga({ payload }) {
  try {
    var data = new FormData();
    data.append("catName", payload.name);
    data.append("description", payload.description);
    data.append("category", payload.id);
    data.append("proCatImage", payload.image, payload.image.name);
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    const res = yield makereq(`category/${payload.id}`, "put", data, headers);
    const updateddata = res.data;
    yield put(editproductcategorysuccess({ updateddata }));
    toaster("success", "Product Category Updated Successfully");
  } catch (error) {
    toaster("error", "Error Occured");
  }
}
export function* fetchProductCategorySaga({ payload }) {
  try {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const res = yield makereq(`category/`, "get", null, headers);
    const { data } = res;
    yield put(fetchProductCategorySuccess({ data }));
  } catch (error) {}
}

export function* deleteProductCategorySaga({ payload }) {
  try {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    yield makereq(`category/${payload}`, "delete", null, headers);
    yield put(deleteproductcategorysuccess({ payload }));
    toaster("success", "Product deleted Successfully");
  } catch (error) {
    toaster("error", "Error Occured");
  }
}
