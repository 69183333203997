import { categoryActions } from "../actionTypes";

const intitalState = {
  category: [],
  loading: false,
};

export const categoryReducer = (state = intitalState, action) => {
  const { payload, type } = action;
  switch (type) {
    case categoryActions.FETCH_CATEGORY_INITIATE: {
      return {
        ...state,
        loading: true,
      };
    }
    case categoryActions.FETCH_CATEGORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        category: payload,
      };
    }
    case categoryActions.FETCH_CATEGORY_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
