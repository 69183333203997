import { put } from "redux-saga/effects";
import {
  fetchProductSuccess,
  fetchShopSuccess,
} from "../actionCreators/vehicleActions";
import { makereq, toaster } from "../../utils/utils";

export function* fetchShopSaga() {
  try {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const res = yield makereq("shop", "get", null, headers);
    const { data } = res;
    yield put(fetchShopSuccess({ data }));
  } catch (error) {
    error.message && error.message === "invalid token ..."
      ? toaster("error", "please login agian")
      : toaster("error", "Error  Occured");
  }
}

export function* fetchProductSaga() {
  try {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const res = yield makereq("product/search", "post", null, headers);
    const data = res.data.payload;
    yield put(fetchProductSuccess({ data }));
  } catch (error) {
    error.message && error.message === "invalid token ..."
      ? toaster("error", "please login agian")
      : toaster("error", "Error Occured");
  }
}
