import { put } from "redux-saga/effects";
import {
  addVehicleSuccess,
  editVehicleSuccess,
  fetchVehicleSuccess,
  deleteVehicleSuccess,
  fetchTermsOfUseSuccess,
  fetchPrivacyPolicySuccess,
} from "../actionCreators/vehicleActions";
import { makereq, toaster } from "../../utils/utils";
var FormData = require("form-data");

export function* addVeicleSaga({ payload }) {
  try {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    const formData = new FormData();
    formData.append("vehicalName", payload.name);
    formData.append("category", payload.category);
    formData.append("address", payload.address);
    formData.append("zipCode", payload.zipcode);
    formData.append("latitude", payload.latitude);
    formData.append("longitude", payload.longitude);
    formData.append("description", payload.description);
    formData.append("vehicalImage", payload.vehicalImage);
    const res = yield makereq(
      `vehical/${payload._id}`,
      "POST",
      formData,
      headers
    );
    const { data } = res;
    yield put(addVehicleSuccess({ data }));
    toaster("success", "Vehicle Added Successfully");
  } catch (error) {}
}

export function* fetchvehicleSaga({ payload }) {
  try {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const res = yield makereq("vehical/", "get", {}, headers);
    const { data } = res;
    yield put(fetchVehicleSuccess({ data }));
  } catch (error) {
    error.message === "invalid token..."
      ? toaster("error", "please login again")
      : toaster("error", error.message);
  }
}

export function* deleteVehicleSaga({ payload }) {
  try {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    yield makereq(`vehical/${payload}`, "delete", {}, headers);
    yield put(deleteVehicleSuccess({ payload }));
  } catch (error) {}
}

export function* editVehicleSaga({ payload }) {
  try {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const formData = new FormData();
    formData.append("vehicalName", payload.name);
    formData.append("category", payload.category);
    formData.append("address", payload.address);
    formData.append("zipCode", payload.zipcode);
    formData.append("latitude", payload.latitude);
    formData.append("longitude", payload.longitude);
    formData.append("description", payload.description);
    formData.append("vehicalImage", payload.vehicalImage);
    const res = yield makereq(
      `vehical/${payload.id}`,
      "POST",
      formData,
      headers
    );
    const { data } = res;
    yield put(editVehicleSuccess({ data }));
  } catch (error) {}
}

export function* fetchPrivacypolicySaga() {
  try {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const res = yield makereq("static-pages/privacyPolicy", "get", {}, headers);
    const { data } = res;
    yield put(fetchPrivacyPolicySuccess({ data }));
  } catch (error) {
    error.message === "invalid token..."
      ? toaster("error", "please login again")
      : toaster("error", error.message);
  }
}

export function* fetchTermsOfUseSaga() {
  try {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const res = yield makereq("static-pages/termsOfUse", "get", {}, headers);
    const { data } = res;
    yield put(fetchTermsOfUseSuccess({ data }));
  } catch (error) {
    error.message === "invalid token..."
      ? toaster("error", "please login again")
      : toaster("error", error.message);
  }
}
