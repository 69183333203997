// fetchproductsubcategory
import { put } from "redux-saga/effects";
import { fetchOrdersSuccess } from "../actionCreators/orderActions";
import { makereq, toaster } from "../../utils/utils";

export function* fetchAllOrderssSaga({ payload }) {
  try {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const res = yield makereq(`order/allOrders`, "get", {}, headers);
    const { data } = res;
    yield put(fetchOrdersSuccess({ data }));
  } catch (error) {
    error.message
      ? toaster("error", error.message)
      : toaster("error", "Error Occured");
  }
}
