import { combineReducers } from "redux";

import { authReducer } from "./reducer/authreducer";
import { vehicleReducer } from "./reducer/vehiclereducer";
import { shopcategoryReducer } from "./reducer/shopcategory";
import { settingsReducers } from "./reducer/settingsreducers";
import { staticpageReducer } from "./reducer/staticpagereducer";
import { vehicleCategoryReducer } from "./reducer/vehiclecategoryreducer";
import { productCategoryReducer } from "./reducer/productcategortreducer";
import { productSubCategoryReducer } from "./reducer/productSubcatreducer";
import { transactionReducer } from "./reducer/transactionreducer";
import { userReducer } from "./reducer/userreducers";
import { orderReducer } from "./reducer/orderReducer";
import { categoryReducer } from "./reducer/categoryReducer";
import {
  riskCategoryReducer,
  riskSubCategoryReducer,
} from "./reducer/riskReducer";

const rootReducer = combineReducers({
  adminState: authReducer,
  orderState: orderReducer,
  vehicleState: vehicleReducer,
  settingsState: settingsReducers,
  staticpageState: staticpageReducer,
  transactionState: transactionReducer,
  userState: userReducer,
  shopcategoryState: shopcategoryReducer,
  productCategoryState: productCategoryReducer,
  vehicleCategoryState: vehicleCategoryReducer,
  productSubCategoryState: productSubCategoryReducer,
  categoryState: categoryReducer,
  riskcategoryState: riskCategoryReducer,
  riskSubcategoryState: riskSubCategoryReducer,
});

export default rootReducer;
