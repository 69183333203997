import axios from './api';
import { toast } from 'react-toastify';
import { set, has, isEmpty, isArray } from 'lodash';
import produce from 'immer';
import {
  noIntenetConnection,
  requestSuccess,
  somehingwentWrong,
} from './alertMessages';
import Storage from './storage';

const baserurl = process.env.REACT_APP_BASE_URL;

export const isValidUrl = urlString => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

export const handleApiResponseSuccess = msg => {
  toaster('success', msg || requestSuccess);
};

export const handleApiResponseErrors = msg => {
  toaster('error', msg || somehingwentWrong, 6000);
};

export const getHeaders = () => {
  return {
    Accept: 'application/json, text/plain, */*',
    Authorization: `Bearer ${Storage.loadToken()}`,
    'Content-Type': 'application/json',
  };
};

export function makereq(url, method, data, headers = getHeaders()) {
  var config = {
    method: method,
    url: baserurl + url,
    headers: {
      ...headers,
      Authorization: getHeaders().Authorization,
    },
    data: data,
  };
  if (navigator.onLine) {
    return new Promise((resolve, reject) => {
      axios(config)
        .then(res => {
          if (res?.response && res.response?.status !== 200) {
            const message = res.response?.data?.message;
            reject(message);
          }
          resolve(res.data);
        })
        .catch(function (error) {
          if (error?.response) {
            const message = error.response?.data?.message;
            reject(message);
          } else if (error?.request) {
            reject(error.request?.data);
          }
        });
    });
  } else {
    toaster('error', noIntenetConnection);
  }
}

export function makereqWithValidationError(
  url,
  method,
  data,
  headers = getHeaders(),
  params
) {
  var config = {
    method: method,
    url: baserurl + url,

    headers: {
      ...headers,
      Authorization: getHeaders().Authorization,
    },
    data: data,
  };
  if (params) {
    config.params = params;
  }
  if (navigator.onLine) {
    return new Promise((resolve, reject) => {
      axios(config)
        .then(res => {
          console.log(res, 'res');
          if (res?.response && res.response?.status !== 200) {
            const errorValidationData = res.response?.data?.data;

            if (
              isArray(errorValidationData) &&
              errorValidationData.length > 0
            ) {
              const msg = errorValidationData
                ?.map((itm, key) => itm.msg)
                .join(',');
              return reject(msg);
            } else {
              const message = res.response?.data?.message;

              return reject(message);
            }
          }
          resolve(res.data);
        })
        .catch(function (error) {
          console.log('errrroooor', error);
          if (error?.response) {
            const message = error.response?.data?.message;
            reject(message);
          } else if (error?.request) {
            reject(error.request?.data);
          }
        });
    });
  } else {
    toaster('error', noIntenetConnection);
  }
}

export function stringvalidator(name) {
  if (!name) {
    return false;
  }
  if (name.trim().length === 0) {
    return false;
  }
  return true;
}

export function toaster(type, msg, toaster = 2000) {
  if (type === 'error') {
    toast.error(msg, {
      position: 'top-right',
      autoClose: toaster,
      closeOnClick: true,
      pauseOnHover: true,
      pauseOnFocusLoss: false,
    });
    return;
  }
  if (type === 'success') {
    toast.success(msg, {
      position: 'top-right',
      autoClose: 2000,
      closeOnClick: true,
      pauseOnHover: true,
      pauseOnFocusLoss: false,
    });
    return;
  }
  if (type === 'warning') {
    toast.warning(msg, {
      position: 'top-right',
      autoClose: 2000,
      closeOnClick: true,
      pauseOnHover: true,
      pauseOnFocusLoss: false,
    });
    return;
  }
  toast.info(msg, {
    position: 'top-right',
    autoClose: 2000,
    closeOnClick: true,
    pauseOnHover: true,
    pauseOnFocusLoss: false,
  });
  return;
}

export const enhancedReducer = (state, updateArg) => {
  if (updateArg.constructor === Function) {
    return { ...state, ...updateArg(state) };
  }

  if (updateArg.constructor === Object) {
    if (has(updateArg, 'path') && has(updateArg, 'value')) {
      const { path, value } = updateArg;

      return produce(state, draft => {
        set(draft, path, value);
      });
    } else if (!isEmpty(updateArg)) {
      return { ...state, ...updateArg };
    }
  }

  return state;
};

export const isObjEmpty = obj1 => {
  let flag = true;
  const traverseNode = (arr, id, n, obj) => {
    if (id >= n) return;

    if (obj[arr[id]] instanceof Object) {
      traverseNode(
        Object.keys(obj[arr[id]]),
        0,
        Object.keys(obj[arr[id]]).length,
        obj[arr[id]]
      );
    } else if (obj[arr[id]] !== '') {
      flag = false;

      return;
    } else {
      traverseNode(arr, id + 1, n, obj);
    }
  };
  traverseNode(Object.keys(obj1), 0, Object.keys(obj1).length, obj1);

  return flag;
};

export default function componentLoader(lazyComponent, attemptsLeft = 3) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch(error => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(
            resolve,
            reject
          );
        }, 1500);
      });
  });
}
