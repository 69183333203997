import { React, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BiCategory } from 'react-icons/bi';
import { BsCardChecklist, BsList, BsFillCaretRightFill } from 'react-icons/bs';
import { VscListOrdered } from 'react-icons/vsc';
import { RiFileList2Line, RiShapeFill } from 'react-icons/ri';
import { IoNotificationsSharp } from 'react-icons/io5';
import { FaListAlt } from 'react-icons/fa';
import LOGO from '../../assets/logo.ico';
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategoryInitiate } from '../../redux/actionCreators/categoryAction';
import { isEmpty } from 'lodash';
import { fetchRiskCategoryInitiate } from '../../redux/actionCreators/riskActions';

const AdminLtSide = () => {
  const category = useSelector(state => state?.categoryState?.category);
  const { riskCategory } = useSelector(state => state.riskcategoryState);
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const loggedin = useSelector(state => state.adminState.loggedin);
  const hideComponent =
    pathname?.includes('/transection/detail/') || pathname?.includes('/FAQ');

  useEffect(() => {
    if (loggedin) {
      if (isEmpty(category)) dispatch(fetchCategoryInitiate());
      if (isEmpty(riskCategory)) dispatch(fetchRiskCategoryInitiate());
    }
  }, [dispatch, loggedin, category, riskCategory]);
  return (
    !hideComponent && (
      <>
        {/* <!-- Main Sidebar Container --> */}
        <aside
          className={`main-sidebar sidebar-dark-primary elevation-4 ${
            loggedin ? '' : 'd-none'
          }`}
        >
          {/* <!-- Brand Logo --> */}
          <Link to="/home" className="brand-link textdecoration">
            <img
              className="img-fluid rounded mx-3"
              alt=""
              src={LOGO}
              width="30px"
            />
            <span className="brand-text font-weight-light ">Prospuh</span>
          </Link>

          {/* <!-- Sidebar --> */}
          <div className="sidebar">
            {/* <!-- Sidebar user panel (optional) --> */}
            <div className="user-panel mt-3 pb-3 mb-3 d-flex">
              <div className="image">
                <i
                  className="img-circle fas fa-user-shield fa-2x"
                  style={{ color: '#fff' }}
                ></i>
              </div>
              <div className="info" style={{ color: 'white' }}>
                Admin
              </div>
            </div>
            {/* <!-- Sidebar Menu --> */}
            <nav className="mt-2">
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="true"
              >
                <li className="nav-item ">
                  <Link
                    to="/home"
                    className={`nav-link ${
                      pathname === '/home' ? 'active' : 'removebgColor'
                    }`}
                  >
                    <i className="nav-icon fas fa-tachometer-alt" />
                    <p>Dashboard</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/user"
                    className={`nav-link ${
                      pathname === '/user' ? 'active' : 'removebgColor'
                    }`}
                  >
                    <i className="nav-icon fa-solid fa-user fas" />
                    <p>User</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/userinprogress"
                    className={`nav-link ${
                      pathname === '/userinprogress'
                        ? 'active'
                        : 'removebgColor'
                    }`}
                  >
                    <i className="nav-icon fa-solid fa-user-plus fas" />
                    <p>UserInProgress</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/review-user"
                    className={`nav-link ${
                      pathname === '/review-user' ? 'active' : 'removebgColor'
                    }`}
                  >
                    <i className="nav-icon fa-solid fa-user fas" />
                    <p>Review Needed</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/all-users"
                    className={`nav-link ${
                      pathname === '/all-users' ? 'active' : 'removebgColor'
                    }`}
                  >
                    <i className="nav-icon fas fa-user-plus" />
                    <p>All Users</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/user-registration"
                    className={`nav-link ${
                      pathname === '/user-registration'
                        ? 'active'
                        : 'removebgColor'
                    }`}
                  >
                    <i className="nav-icon fas fa-user-plus" />
                    <p>User Register</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/admin-list"
                    className={`nav-link ${
                      pathname === '/admin-list' ? 'active' : 'removebgColor'
                    }`}
                  >
                    <i className="nav-icon fa-solid fa-users fas" />
                    <p>Sub Admin</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/category"
                    className={`nav-link ${
                      pathname === '/category' ? 'active' : 'removebgColor'
                    }`}
                  >
                    <BiCategory className="mx-1 " />
                    <p>Category</p>
                  </Link>
                </li>

                <li className={`nav-item`}>
                  <Link className="nav-link">
                    <i className="nav-icon fas fa-copy"></i>
                    <p>
                      Themes
                      <i className="fas fa-angle-right right"></i>
                      <span className="badge badge-info right">
                        {category && category.length}
                      </span>
                    </p>
                  </Link>

                  <ul
                    className="nav nav-treeview"
                    style={{ display: 'none' }}
                    id="Themes"
                  >
                    {category &&
                      category?.map(data => (
                        <li className="nav-item" key={data?._id}>
                          <Link
                            to={`/category/manage/share/${data?.categoryName}/${data?._id}`}
                            className={`nav-link ${
                              pathname ===
                              `/category/manage/share/${data?.categoryName
                                .split(' ')
                                .join('%20')}/${data?._id}`
                                ? 'active'
                                : ''
                            }`}
                          >
                            <BsFillCaretRightFill className="mx-1 " />
                            <p>{data?.categoryName}</p>
                          </Link>
                        </li>
                      ))}
                  </ul>
                </li>
                <li className="nav-item">
                  <Link
                    to="/risksection"
                    className={`nav-link ${
                      pathname === '/risksection' ? 'active' : 'removebgColor'
                    }`}
                  >
                    <BiCategory className="mx-1 " />
                    <p>Risk Section</p>
                  </Link>
                </li>

                <li className={`nav-item`}>
                  <Link className="nav-link">
                    <i className="nav-icon fas fa-copy"></i>
                    <p>
                      Risk Themes
                      <i className="fas fa-angle-right right"></i>
                      <span className="badge badge-info right">
                        {riskCategory && riskCategory.length}
                      </span>
                    </p>
                  </Link>

                  <ul
                    className="nav nav-treeview"
                    style={{ display: 'none' }}
                    id="Themes"
                  >
                    {riskCategory &&
                      riskCategory?.map(data => (
                        <li className="nav-item" key={data?._id}>
                          <Link
                            to={`/risksection/managesubcategory/${data?._id}`}
                            className={`nav-link ${
                              pathname ===
                              `/risksection/managesubcategory/${data?._id}`
                                ? 'active'
                                : ''
                            }`}
                          >
                            <BsFillCaretRightFill className="mx-1 " />
                            <p>{data?.categoryName}</p>
                          </Link>
                        </li>
                      ))}
                  </ul>
                </li>

                <li className={`nav-item`}>
                  <Link className="nav-link">
                    <i className="nav-icon fas fa-copy"></i>
                    <p>
                      Automated Investment
                      <i className="fas fa-angle-right right"></i>
                      <span className="badge badge-info right">
                        {riskCategory && riskCategory.length}
                      </span>
                    </p>
                  </Link>

                  <ul
                    className="nav nav-treeview"
                    style={{ display: 'none' }}
                    id="Themes"
                  >
                    {riskCategory &&
                      riskCategory?.map(data => (
                        <li className="nav-item" key={data?._id}>
                          <Link
                            to={`/automated-investment/${data?._id}`}
                            className={`nav-link ${
                              pathname === `/automated-investment/${data?._id}`
                                ? 'active'
                                : ''
                            }`}
                          >
                            <BsFillCaretRightFill className="mx-1 " />
                            <p>{data?.categoryName}</p>
                          </Link>
                        </li>
                      ))}
                  </ul>
                </li>

                <li className="nav-item">
                  <Link
                    to="/default/asset"
                    className={`nav-link ${
                      pathname === '/default/asset' ? 'active' : 'removebgColor'
                    }`}
                  >
                    <i className="nav-icon fas ">
                      <BsCardChecklist />
                    </i>
                    <p>Default List</p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/mostbought/asset"
                    className={`nav-link ${
                      pathname === '/mostbought/asset'
                        ? 'active'
                        : 'removebgColor'
                    }`}
                  >
                    <i className="nav-icon fas ">
                      <FaListAlt />
                    </i>
                    <p>Most Bought List</p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/topanalystpick/asset"
                    className={`nav-link ${
                      pathname === '/topanalystpick/asset'
                        ? 'active'
                        : 'removebgColor'
                    }`}
                  >
                    <i className="nav-icon fas ">
                      <BsList />
                    </i>
                    <p>Top Analyst Pick</p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/popular/asset"
                    className={`nav-link ${
                      pathname === '/popular/asset' ? 'active' : 'removebgColor'
                    }`}
                  >
                    <i className="nav-icon  ">
                      <VscListOrdered className="mx-1 " />
                    </i>
                    <p>Popular Share</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/ETFs/asset"
                    className={`nav-link ${
                      pathname === '/ETFs/asset' ? 'active' : 'removebgColor'
                    }`}
                  >
                    <i className="nav-icon">
                      <RiFileList2Line className="mx-1 " />
                    </i>
                    <p>Popular ETFs</p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link">
                    <i className="nav-icon">
                      <RiShapeFill className="mx-1 " />
                    </i>
                    <p>
                      Actions
                      <i className="fas fa-angle-right right"></i>
                    </p>
                  </Link>
                  <ul
                    className="nav nav-treeview"
                    style={{ display: 'none' }}
                    id="transactions"
                  >
                    <li className="nav-item ">
                      <Link
                        to="/user-view"
                        className={`nav-link ${
                          pathname === '/user-view' ? 'active' : 'removebgColor'
                        }`}
                      >
                        <BsFillCaretRightFill className="mx-1 " />
                        <p>User View</p>
                      </Link>
                    </li>
                    <li className="nav-item ">
                      <Link
                        to={`/addfund-report`}
                        className={`nav-link ${
                          pathname === '/addfund-report'
                            ? 'active'
                            : 'removebgColor'
                        }`}
                      >
                        <BsFillCaretRightFill className="mx-1 " />
                        <p>Add Fund List</p>
                      </Link>
                    </li>
                    <li className="nav-item ">
                      <Link
                        to={`/withdrawalfund-report`}
                        className={`nav-link ${
                          pathname === '/withdrawalfund-report'
                            ? 'active'
                            : 'removebgColor'
                        }`}
                      >
                        <BsFillCaretRightFill className="mx-1 " />
                        <p>Withdrawal List</p>
                      </Link>
                    </li>
                    <li className="nav-item ">
                      <Link
                        to={`/buyOrders-report`}
                        className={`nav-link ${
                          pathname === '/buyOrders-report'
                            ? 'active'
                            : 'removebgColor'
                        }`}
                      >
                        <BsFillCaretRightFill className="mx-1 " />
                        <p>Buy Order List</p>
                      </Link>
                    </li>
                    <li className="nav-item ">
                      <Link
                        to={`/sellOrders-report`}
                        className={`nav-link ${
                          pathname === '/sellOrders-report'
                            ? 'active'
                            : 'removebgColor'
                        }`}
                      >
                        <BsFillCaretRightFill className="mx-1 " />
                        <p>Sell Order List</p>
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item">
                  <Link className="nav-link">
                    <i className="nav-icon fas fa-money-check-alt"></i>
                    <p>
                      Transaction
                      <i className="fas fa-angle-right right"></i>
                      <span className="badge badge-info right">2</span>
                    </p>
                  </Link>
                  <ul
                    className="nav nav-treeview"
                    style={{ display: 'none' }}
                    id="transactions"
                  >
                    <li className="nav-item ">
                      <Link
                        to={`/transactions/addfund`}
                        className={`nav-link ${
                          pathname === '/transactions/addfund'
                            ? 'active'
                            : 'removebgColor'
                        }`}
                      >
                        <BsFillCaretRightFill className="mx-1 " />
                        <p>Add Fund History </p>
                      </Link>
                    </li>
                    <li className="nav-item ">
                      <Link
                        to={`/transactions/`}
                        className={`nav-link ${
                          pathname === '/transactions/'
                            ? 'active'
                            : 'removebgColor'
                        }`}
                      >
                        <BsFillCaretRightFill className="mx-1 " />
                        <p>Withdraw Fund History</p>
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item">
                  <Link
                    to="/Notification/list"
                    className={`nav-link ${
                      pathname === '/Notification/list'
                        ? 'active'
                        : 'removebgColor'
                    }`}
                  >
                    <IoNotificationsSharp className="mx-1 " />
                    <p>Notification</p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/static-pages"
                    className={`nav-link ${
                      pathname === '/static-pages' ? 'active' : 'removebgColor'
                    }`}
                  >
                    <i className="nav-icon fas fa-file-alt" />
                    <p>Static Pages</p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/contact-us"
                    className={`nav-link ${
                      pathname === '/contact-us' ? 'active' : 'removebgColor'
                    }`}
                  >
                    <i className="nav-icon fas fa-phone-square" />
                    <p>Contact Us</p>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </aside>
        {/* <!-- Control Sidebar --> */}
      </>
    )
  );
};
export default AdminLtSide;
