// fetchproductsubcategory
import { put } from "redux-saga/effects";
import {
  addSubcategorySuccess,
  deleteSubcategorySuccess,
  editSubcategorySuccess,
  fetchallSubCategorySuccess,
  fetchProductSubCategorySuccess,
} from "../actionCreators/productSubcategoryActions";
import { makereq, toaster } from "../../utils/utils";

export function* addSubcategorySaga({ payload }) {
  try {
    const formData = new FormData();
    formData.append("name", payload.name);
    formData.append("description", payload.description);
    formData.append("category", payload.category);
    formData.append("proSubCatImage", payload.image, payload.image.name);

    const headers = {
      "Content-Type": "multipart/form-data",
    };
    const res = yield makereq(`subCategory`, "post", formData, headers);
    const { data } = res;
    yield put(addSubcategorySuccess({ data }));
    toaster("success", "Subcategory added successfully");
  } catch (error) {
    error.message
      ? toaster("error", error.message)
      : toaster("error", "Error Occured");
  }
}

export function* editSubcategorySaga({ payload }) {
  try {
    const formData = new FormData();
    formData.append("catname", payload.name);
    formData.append("description", payload.description);
    formData.append("category", payload.category);
    formData.append("proSubCatImage", payload.image, payload.image.name);

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    const res = yield makereq(
      `subCategory/${payload.id}`,
      "put",
      formData,
      headers
    );
    const { data } = res;
    yield put(editSubcategorySuccess({ data }));
    toaster("success", "Sub Category updated successfully");
  } catch (error) {
    error.message
      ? toaster("error", error.message)
      : toaster("error", "Error Occured");
  }
}

export function* fetchproductsubcategorySaga({ payload }) {
  try {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const res = yield makereq(`subCategory/${payload}`, "get", {}, headers);
    const { data } = res;
    yield put(fetchProductSubCategorySuccess({ data }));
  } catch (error) {
    error.message
      ? toaster("error", error.message)
      : toaster("error", "Error Occured");
  }
}

export function* fetchallSubCategorySaga({ payload }) {
  try {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const res = yield makereq(`subCategory/`, "get", {}, headers);
    const { data } = res;
    yield put(fetchallSubCategorySuccess({ data }));
  } catch (error) {
    error.message
      ? toaster("error", error.message)
      : toaster("error", "Error Occured");
  }
}

export function* deleteSubCatSaga({ payload }) {
  try {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    yield makereq(`subCategory/${payload}`, "delete", {}, headers);
    yield put(deleteSubcategorySuccess(payload));
    toaster("success", "Sub category deleted successfully");
  } catch (error) {
    error.message
      ? toaster("error", error.message)
      : toaster("error", "Error Occured");
  }
}
