import { put } from "redux-saga/effects";
import {
  fetchRiskCategoryFail,
  fetchRiskCategorySuccess,
  fetchSubRiskCategoryFail,
  fetchSubRiskCategorySuccess,
} from "../actionCreators/riskActions";
import { manageRiskSection, manageSubRiskSection } from "../../services/api";

export function* fetchRiskCategorySaga() {
  try {
    const res = yield manageRiskSection();
    yield put(fetchRiskCategorySuccess(res));
  } catch (error) {
    yield put(fetchRiskCategoryFail());
  }
}

export function* fetchSubRiskCategorySaga({ payload }) {
  try {
    const res = yield manageSubRiskSection(payload);
    yield put(fetchSubRiskCategorySuccess(res));
  } catch (error) {
    yield put(fetchSubRiskCategoryFail());
  }
}
