import { categoryActions } from "../actionTypes";

export const fetchCategoryInitiate = () => {
  return {
    type: categoryActions.FETCH_CATEGORY_INITIATE,
  };
};

export const fetchCategorySuccess = (data) => {
  return {
    type: categoryActions.FETCH_CATEGORY_SUCCESS,
    payload: data,
  };
};

export const fetchCategoryFail = () => {
  return {
    type: categoryActions.FETCH_CATEGORY_FAIL,
  };
};
