import { put } from "redux-saga/effects";
import {
  addstaticPageFail,
  addstaticPageSuccess,
  deletestaticPageSuccess,
  editstaticPageSuccess,
  fetchstaticPageFail,
  fetchstaticPageSuccess,
} from "../actionCreators/staticPageActions";
import { makereq, toaster } from "../../utils/utils";
var qs = require("qs");

export function* fetchStaticPageSaga({ payload }) {
  try {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const res = yield makereq("api/static-pages/", "get", null, headers);
    const { data } = res;
    yield put(fetchstaticPageSuccess({ data }));
  } catch (error) {
    yield put(fetchstaticPageFail());
  }
}

export function* addStaticPageSaga({ payload }) {
  try {
    var data = qs.stringify({
      title: payload.title,
      description: payload.description,
      metaKeyword: payload.metaKeyword,
      metaDescription: payload.metaDescription,
    });
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const res = yield makereq("api/static-pages/", "post", data, headers);
    const resData = res.data;
    yield put(addstaticPageSuccess({ resData }));
    toaster("success", res.message);
  } catch (error) {
    toaster("error", error);
    yield put(addstaticPageFail());
  }
}

export function* editStaticPageSaga({ payload }) {
  try {
    var form = qs.stringify({
      title: payload.title,
      description: payload.description,
      metaKeyword: payload.metaKeyword,
      metaDescription: payload.metaDescription,
    });
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const res = yield makereq(
      `api/static-pages/${payload.id}`,
      "put",
      form,
      headers
    );

    const { data } = res;
    yield put(editstaticPageSuccess({ data }));
    toaster("success", res?.message);
  } catch (error) { }
}

export function* deletestaticpageSaga({ payload }) {
  try {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const res = yield makereq(`api/static-pages/${payload}`, "delete", null, headers);
    yield put(deletestaticPageSuccess({ payload }));
    toaster("success", res?.message);
  } catch (error) {
    toaster("error", "Error Occured");
  }
}
