import { transactionActions } from "../actionTypes";

const intitalState = {
  transaction: [],
};

export const transactionReducer = (state = intitalState, action) => {
  const { type } = action;

  switch (type) {
    //  -------------------------Shop Reducers----------------------------------------------
    case transactionActions.FETCH_TRANSACTIONS_INITIATE: {
      return {
        ...state,
      };
    }

    case transactionActions.FETCH_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        transaction: action.payload,
      };
    }
    //  ----------------------------product list fetch actions-------------------------------

    default:
      return {
        ...state,
      };
  }
};
