import { staticPageActions } from "../actionTypes";

const intitalState = {
  aboutusData: {},
  staticpagelist: [],
  loading: false,
  pageOperationCompleted: false,
};

export const staticpageReducer = (state = intitalState, action) => {
  const { payload, type } = action;
  switch (type) {
    case staticPageActions.ADD_STATIC_PAGE_INITIATE: {
      return {
        ...state,
        loading: true,
      };
    }
    case staticPageActions.ADD_STATIC_PAGE_SUCCESS: {
      const { resData } = payload;
      const newObj = {
        _id: resData.id,
        title: resData.title,
        metaKeyword: resData.metaKeyword,
        metaDescription: resData.metaDescription,
        description: resData.description,
      };
      return {
        ...state,
        loading: false,
        pageOperationCompleted: true,
        staticpagelist: [...state.staticpagelist, newObj],
      };
    }
    case staticPageActions.ADD_STATIC_PAGE_FAIL: {
      return {
        ...state,
        loading: false,
        staticpagelist: [],
        pageOperationCompleted: false,
      };
    }

    case staticPageActions.DELETE_STATIC_PAGE_INITIATE: {
      return {
        ...state,
      };
    }
    case staticPageActions.DELETE_STATIC_PAGE_SUCCESS: {
      const res = state.staticpagelist.filter(
        (itm) => itm._id !== payload.payload
      );
      return {
        ...state,
        staticpagelist: res,
        pageOperationCompleted: true,
      };
    }
    case staticPageActions.FETCH_STATIC_PAGE_INITIATE: {
      return {
        ...state,
        loading: true,
        pageOperationCompleted: false,
      };
    }
    case staticPageActions.FETCH_STATIC_PAGE_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        loading: false,
        staticpagelist: [...data],
      };
    }
    case staticPageActions.FETCH_STATIC_PAGE_FAIL: {
      return {
        ...state,
        loading: false,
        staticpagelist: [],
      };
    }
    case staticPageActions.EDIT_STATIC_PAGE_INITIATE: {
      return {
        ...state,
        loading: true,
      };
    }

    case staticPageActions.EDIT_STATIC_PAGE_SUCCESS: {
      const { data } = payload;
      const newObj = {
        _id: data.id,
        title: data.title,
        metaDescription: data.metaDescription,
        metaKeyword: data.metaKeyword,
        description: data.description,
      };
      let index = state.staticpagelist.findIndex((element) => {
        if (element._id === newObj._id) {
          return true;
        }
        return false;
      });
      const newArray = [...state.staticpagelist];
      newArray.splice(index, 1, newObj);
      return {
        ...state,
        loading: false,
        pageOperationCompleted: true,
        staticpagelist: newArray,
      };
    }

    // ----------------------------product list fetch actions-----------------------------
    default: {
      return {
        ...state,
      };
    }
  }
};
